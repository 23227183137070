import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/app/node_modules/.pnpm/next-themes@0.3.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next-themes/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.3_@babel+core@7.26.9_@playwright+test@1.51.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.85.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/[lng]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/node_modules/.pnpm/sonner@1.7.4_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/sonner/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/[lng]/layout.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/providers.tsx");
